<template lang="pug">
main
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/img/real_site_images/resized/the ballroom_setup.jpg')",headline="Meetings & Weddings")

	area-section(
		:img="require('@/assets/img/real_site_images/resized/ballroom facade.jpg')",
		title="The Ball Room",
		subtitle="Parties or Events? We got you.",
		description="10,000 Sq. ft columnless Banquet hall which is extremely spacious for all your needs.",

		icon="people",
		detail="Capacity: 150 - 1000",
		)

	area-section(
		:img="require('@/assets/img/final_3d_images/low/img090.jpg')",
		title="Party Lawn",
		subtitle="From 10,000 sq.ft. - 2,00,000 sq.ft.",
		description="A Stylish Wedding Starts Here",

		icon="people",
		detail="Capacity: 50 - 5000 ",
		)

	area-section(
		:img="require('@/assets/img/final_3d_images/low/img094.jpg')",
		title="Conference",
		subtitle="Like smartphone it is a smart conference room",
		description="smart conference room designed for hybrid meetings and collaboration in modern workplace with all solutions",

		icon="people",
		detail="Capacity: 20 - 100",
		)
	
	area-section(
		:img="require('@/assets/img/real_site_images/resized/amphitheatre.jpg')",
		title="Amphitheatre",
		subtitle="Open air Amphitheatre to enjoy the show in breeze.",
		description="Stadium like seating to have the perfect stage views",

		icon="people",
		detail="Capacity: 200 - 1000",
		)

	location

</template>

<script>

// Imports
import DisplayHeader from '../components/DisplayHeader.vue'
import AreaSection from '../components/XAreaSection.vue'
import Location from '../components/Location.vue'

// Vue Config
export default {
	 name: "",
  components: {DisplayHeader, AreaSection, Location},
};
</script>

<style>
</style>